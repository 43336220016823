import React from "react";
import { Flex, Heading, Text } from "@chakra-ui/react";
import { Link } from "gatsby";

type CardWithBorderProps = {
  title: string;
  content: string | React.ReactNode;
  link: string;
  className: string;
};

export default function CardWithBorder({
  title,
  content,
  link,
  className,
}: CardWithBorderProps) {
  return (
    <Flex
      borderLeftColor="#32AAB5"
      borderLeftWidth={5}
      flexDirection="column"
      maxWidth="550px"
      px="1rem"
      my="2rem"
      className={className}
    >
      <Link to={link}>
        <Heading style={style.heading} as="h1" size="xl" py="1rem">
          {title}
        </Heading>
      </Link>
      {content}
    </Flex>
  );
}

const style = {
  text: {
    fontFamily: "Open Sans",
    fontSize: "1.25rem",
    color: "#4f4f4f",
  },
  heading: {
    fontFamily: "Montserrat",
    color: "#919E9C",
  },
};
