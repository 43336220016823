import React, { useEffect, useState } from "react";
import {
  Text,
  Box,
  Flex,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Heading,
  background
} from "@chakra-ui/react";
import { isBrowser, m } from "framer-motion";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { motion } from "framer-motion";
import { Link } from "gatsby";

import Seo from "../components/Seo";
import Main from "../components/Main";
import Nav from "../components/Nav";
import indexTags from "./indexTags.json";
import Footer from "../components/Footer";

// @ts-ignore
import gratio from "../images/index/gratio.svg";
// @ts-ignore
import logo1 from "../images/clients/ateneo.png";
// @ts-ignore
import logo2 from "../images/clients/ayala_land.png";
// @ts-ignore
import logo3 from "../images/clients/ayala_premier.png";
// @ts-ignore
import logo4 from "../images/clients/cambridge.png";
// @ts-ignore
import logo5 from "../images/clients/cignal.png";
// @ts-ignore
import logo6 from "../images/clients/emerson.png";
// @ts-ignore
import logo7 from "../images/clients/essilor.png";
// @ts-ignore
import logo8 from "../images/clients/globe.png";
// @ts-ignore
import logo9 from "../images/clients/kimberly_clark.png";
// @ts-ignore
import logo10 from "../images/clients/one_sports.png";
// @ts-ignore
import logo11 from "../images/clients/smart.png";
// @ts-ignore
import logo12 from "../images/clients/unilab.png";
// @ts-ignore
import mobile from "../images/index/Mobile-white.svg";
// @ts-ignore
import android from "../images/index/android.png";
// @ts-ignore
import iphoneX from "../images/index/iphoneX.png";

import mobileApp from "../images/testimonials/iphone-home.png";

import { Iphone, Ipad, CircBG } from "../components/Graphics";
import CardWithBorder from "../components/CardWithBorder";
import "../assets/css/index.css";

gsap.registerPlugin(ScrollTrigger);

export default function index() {
  // const logos = [apple, discord, addidas, boom, nasa, tesla, twitch];
  const { isOpen, onOpen, onClose } = useDisclosure();

  const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7];
  useEffect(() => {
    // gsap timeline animations
    let mounted = true;

    if (mounted) {
      if (isBrowser) {
        const tl = gsap.timeline();
        const tl2 = gsap.timeline({
          scrollTrigger: {
            trigger: ".section2",
            start: "center bottom",
          },
        });
        const tl3 = gsap.timeline({
          scrollTrigger: {
            trigger: ".terraces",
            start: "40% center",
            scrub: 1,
          },
        });

        tl2
          .fromTo(".section2", { opacity: 0 }, { duration: 0.5, opacity: 1 })
          .fromTo(".motto", { opacity: 0 }, { duration: 0.5, opacity: 1 })
          .fromTo(".best", { opacity: 0 }, { duration: 0.5, opacity: 1 }, ">")
          .fromTo(
            ".iphone",
            { opacity: 0, x: -100 },
            { duration: 0.25, opacity: 1, x: 0 },
            ">"
          )
          .fromTo(
            ".ipad",
            { opacity: 0, x: -100 },
            { duration: 0.25, opacity: 1, x: 0 },
            ">"
          );
      }
    }

    const cardsTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: ".cardsSection",
        start: "top center",
      },
    });

    cardsTimeline
      .fromTo(".circBg", { opacity: 0 }, { opacity: 1, duration: 0.5 })
      .fromTo(".card1", { opacity: 0 }, { opacity: 1, duration: 0.5 }, ">")
      .fromTo(".card2", { opacity: 0 }, { opacity: 1, duration: 0.5 }, ">")
      .fromTo(".card3", { opacity: 0 }, { opacity: 1, duration: 0.5 }, ">")
      .fromTo(".iphoneX", { opacity: 0 }, { opacity: 1, duration: 0.5 }, "<-=1")
      .fromTo(".tablet", { opacity: 0 }, { opacity: 1, duration: 0.5 }, ">");

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Box className="main-bg" style={{background: "#080815"}}>
      <Seo
        title={indexTags.title}
        url={indexTags.url}
        description={indexTags.description}
      />

<Nav active="home" />
<Main>
      <Flex
         w="100%"
         justifyContent="space-evenly"
         wrap="wrap"
         px="2rem">
          <Flex
            justifySelf={"start"}
            flexDir="column"
            maxW="700px"
            my={["0rem", "6rem", "0rem", "6rem"]}
            w={["100%", "100%", "40%"]}>
            <Heading
              color="#ffffff"
              fontSize="1.75rem"
              fontFamily="Montserrat"
              className="services">
              We build <span style={{color: "#84EFEF"}}>beautiful software</span> for brands & businesses.
            </Heading>
            <Text
              className="subHeading"
              fontFamily="Open Sans"
              fontSize="1.25rem"
              color={"#ffffff"}
              my="2rem"
            >
              <span style={{color: "#84EFEF"}}>Trusted</span> by clients for <span style={{color: "#84EFEF"}}>15+</span> years.

            </Text>

            <Text
              className="subHeading"
              fontFamily="Open Sans"
              fontSize="1.10rem"
              color={"#ffffff"}
              my="0.75rem"
            >

              Tell us about your project. We will help you design, develop, test, manage and successfully deploy software that works.

            </Text><br />
            <Link  to="/contact-us">
            <Button
            borderRadius='80px'
            style={{background: "#0D2B9B", width: 175, height: 40}}
            my="0.5rem">
              <Text
                className="subHeading"
                fontFamily="Open Sans"
                fontSize="0.75rem"
                textAlign="left"
                color={"#ffffff"}>
                Schedule a call
              </Text>
            </Button>
            </Link>
          </Flex>

          <Image
            src={mobileApp}
            objectFit={"contain"}
            className="amonite"
            boxSize={"600px"} />

      </Flex>

        <Flex
          className="section2"
          w="100%"
          my={["8rem", "1rem", "8rem", "2rem", "2rem"]}
          alignItems="center"
          flexDirection="row"
          justifyContent="center"
          flexWrap="wrap-reverse"
          backgroundImage={gratio}
          backgroundSize={["cover", "cover", "cover", "cover", "70%"]}
          backgroundRepeat={"no-repeat"}
          backgroundPosition={[
            "center",
            "center",
            "center",
            "center",
            "-100px -220px",
          ]}
          h="auto"
          // minHeight={"100vh"}
        >
          <Flex justifyContent={"center"} my="2rem">
            <Ipad />
            <Iphone />
          </Flex>
          <Flex
            width={["100%", "100%", "100%", "100%", "40%"]}
            flexDirection="column"
            px="2rem"
            className="motto"
          >
            <Text
              alignSelf="center"
              style={styles.text}
              fontWeight={600}
              fontSize={["1.25rem", "1.25rem", "1.25rem", "1.5rem", "1.5em"]}
              my="2rem"
              color="#FFFFFF"
              textAlign={"justify"}
            >
              Rogomi is a software development agency passionate about
              birthing ideas to reality.
              <br />
              <br />
              We focus on creating innovative digital products through fruitful
              and joyful collaborations.
            </Text>
            <Text
              // alignSelf="flex-end"
              style={styles.text}
              fontWeight={400}
              fontSize={["1.25rem", "1.25rem", "1.25rem", "1.5rem", "1.5em"]}
              color="#06667C"
              className="best"
              textAlign={"justify"}
            >
              Together, we awaken possibilities.
            </Text>
            <Button
              alignSelf={[
                "center",
                "center",
                "center",
                "center",
                "center",
                "flex-start",
              ]}
              backgroundColor="#32AAB5"
              color="#ffff"
              my="3rem"
              width={"300px"}
              height={"2rem"}
              px="1rem"
              py={"1.5rem"}
              fontFamily="Open sans"
              cursor="pointer"
              boxShadow={
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
              }
              _hover={{
                backgroundColor: "#FDDA4A",
                background: `url(${gratio}) no-repeat`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: "#000",
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
                transition: "all 0.25s ease-in",
              }}
              onClick={() => {
                onOpen();
              }}
            >
              Find Out How
            </Button>
          </Flex>
        </Flex>
        <Flex
          // backgroundColor="#32AAB5"
          h="auto"
          // h={["200px", "170px", "150px", "150px"]}
          w="100%"
          // boxShadow="inset 0 0 10px #000000"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          borderRadius={10}
        >
          <Flex py="1rem">
            <Text
              style={styles.text}
              fontSize={["1.5rem", "1.5rem", "1.5rem", "1.75rem", "1.75rem"]}
              color="#FFFFFF"
              fontWeight="600"
              textAlign={"center"}
            >
              Teams We’ve Worked With
            </Text>
          </Flex>
          <Flex
            justifyContent={"center"}
            flexDirection={["column", "column", "row"]}
            w="100%"
            maxW={"1500px"}
            flexWrap={"wrap"}
            alignItems="center"
          >
            <Image
              src={logo4}
              background={"#FFFFFF"}
              padding={"1rem"}
              borderRadius={"10px"}
              width={["10rem", "12rem", "14rem", "15rem"]}
              m="2rem"
            />
            <Image
              src={logo7}
              width={["10rem", "12rem", "13rem", "10rem"]}
              m="2rem"
            />
            <Image
              src={logo1}
              width={["8rem", "12rem", "13rem", "13rem"]}
              m="2rem"
            />
            <Image
              src={logo3}
              width={["9rem", "11rem", "13rem", "17rem"]}
              m="2rem"
            />
            <Image
              src={logo5}
              width={["9rem", "11rem", "13rem", "12rem"]}
              m="2rem"
            />
            <Image
              src={logo2}
              background={"#FFFFFF"}
              padding={"1rem"}
              borderRadius={"10px"}
              width={["9rem", "11rem", "13rem", "13rem"]}
              m="2rem"
            />
            <Image
              src={logo8}
              background={"#FFFFFF"}
              padding={"1rem"}
              borderRadius={"10px"}
              width={["8rem", "10rem", "13rem", "10rem"]}
              m="2rem"
            />
             <Image
              src={logo9}
              background={"#FFFFFF"}
              padding={"1rem"}
              borderRadius={"10px"}
              width={["8rem", "10rem", "13rem", "10rem"]}
              m="2rem"
            />
             <Image
              src={logo10}
              width={["8rem", "10rem", "13rem", "10rem"]}
              m="2rem"
            />
             <Image
              src={logo11}
              background={"#FFFFFF"}
              padding={"1rem"}
              borderRadius={"10px"}
              width={["8rem", "10rem", "13rem", "10rem"]}
              m="2rem"
            />
             <Image
              src={logo12}
              background={"#FFFFFF"}
              padding={"1rem"}
              borderRadius={"10px"}
              width={["8rem", "10rem", "13rem", "10rem"]}
              m="2rem"
            />
          </Flex>
          <Button
            backgroundColor="#636b6a"
            color="#ffff"
            my="3rem"
            px="3rem"
            py={"1.5rem"}
            fontFamily="Open sans"
            cursor="pointer"
            boxShadow={
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
            }
            _hover={{
              backgroundColor: "#ffff",
              color: "#000",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
            }}
            onClick={() => window.location.assign("/services")}
          >
            Learn More
          </Button>
        </Flex>
        <Flex
          justifyContent={"center"}
          my="2rem"
          display={["flex", "flex", "flex", "flex", "none", "none"]}
        >
          {/* <IphoneX /> */}
          <Image
            src={iphoneX}
            className="iphoneX"
            w={"35rem"}
            h={["20rem", "22rem", "22rem", "25rem", "35rem"]}
            objectFit={"contain"}
            position="relative"
            zIndex={4}
            top={"5rem"}
            left={"7rem"}
          />

          <Image
            src={android}
            className="tablet"
            w={"35rem"}
            h={["20rem", "22rem", "22rem", "30rem", "45rem"]}
            objectFit={"contain"}
            style={{ position: "relative", right: "10rem" }}
          />
        </Flex>
        <Flex
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
          wrap="wrap-reverse"
          w="100%"
          px="2rem"
          my="2rem"
          className="cardsSection"
          minH="1100px"
        >
          <Flex
            display={["none", "none", "none", "flex", "flex", "flex"]}
            position="absolute"
            className="circBg"
          >
            <motion.div
              animate={{
                rotate: 360,
                transition: {
                  duration: 30,
                  ease: "easeInOut",
                  repeat: Infinity,
                  repeatType: "reverse",
                },
              }}
            >
              <CircBG />
            </motion.div>
          </Flex>
          <Flex
            flexDirection="column"
            w={["100%", "100%", "100%", "40%", "40%"]}
            alignItems={["center", "center", "center", "center", "flex-start"]}
            zIndex={10}
            my="4rem"
          >
            <CardWithBorder
              className="card1"
              title="Who We Are"
              link="/about-us"
              content={
                <Flex flexDirection="column">
                  <Text
                    fontSize={"1rem"}
                    style={{
                      fontFamily: "Open Sans",
                      color: "#FFFFFF",
                    }}
                  >
                    We are Rogomi, a team of <em>imagineers</em> and digital
                    services professionals leveraging on technical skills,
                    creativity, imagination and joyful work to create software
                    that helps make the world a better place.
                  </Text>
                  <Link className="cardsButton" to="/about-us">
                    Know <span className="hiddenText">Our Story</span>
                  </Link>
                  <Link className="cardsButton2" to="/about-us">
                    Know our story
                  </Link>
                </Flex>
              }
            />
            <CardWithBorder
              className="card2"
              link="/about-us"
              title="What We Do"
              content={
                <Flex flexDirection="column">
                  <Text
                    fontSize={"1rem"}
                    style={{
                      fontFamily: "Open Sans",
                      color: "#FFFFFF",
                    }}
                  >
                    We develop, design, test and manage software using a
                    time-tested method that has allowed us to publish hundreds
                    of mobile and web applications for startups, SMEs and the
                    world’s largest global brands.
                  </Text>
                  <Link className="cardsButton" to="/services">
                    Explore <span className="hiddenText">Our Projects</span>
                  </Link>
                  <Link className="cardsButton2" to="/services">
                    Explore our projects
                  </Link>
                </Flex>
              }
            />
            <CardWithBorder
              className="card3"
              link="/services"
              title="Why We're Here"
              content={
                <Flex flexDirection={"column"}>
                  <Text
                    fontSize={"1rem"}
                    style={{
                      fontFamily: "Open Sans",
                      color: "#FFFFFF",
                    }}
                  >
                    We are on a mission to awaken possibilities that help
                    humanity reach its full potential. We aim to provide value
                    to the world from a place of creativity, innovation and joy.
                  </Text>

                  <Link className="cardsButton" to="/contact-us">
                    Join <span className="hiddenText">Our Journey</span>
                  </Link>
                  <Link className="cardsButton2" to="/contact-us">
                    Join Us
                  </Link>
                </Flex>
              }
            />
          </Flex>
          <Flex
            justifyContent={"center"}
            my="2rem"
            display={["none", "none", "none", "none", "flex", "flex"]}
          >
            {/* <IphoneX /> */}
            <Image
              src={iphoneX}
              className="iphoneX"
              w={"15rem"}
              h={["20rem", "22rem", "22rem", "25rem", "25rem"]}
              objectFit={"contain"}
              position="relative"
              left="11rem"
              top="16rem"
              zIndex={4}
            />

            <Image
              className="tablet"
              src={android}
              w={"25rem"}
              h={["20rem", "22rem", "22rem", "30rem", "45rem"]}
              objectFit={"contain"}
              style={{ position: "relative" }}
            />
          </Flex>
        </Flex>
      </Main>
      <Footer />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={"6xl"}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody my="2rem" mx="1rem">
            <Flex justifyContent={"center"} alignItems="center" p="auto">
              {/** @ts-ignore */}
              <iframe
                src="https://tally.so/embed/wzx2PR?alignLeft=1&hideTitle=1&transparentBackground=1"
                width="100%"
                height="650px"
                title="Find out more"
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/** @ts-ignore */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WSP4MW7T"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
          `,
        }}
      />
    </Box>
  );
}

const styles = {
  text: {
    fontFamily: "Open Sans",
  },
};
